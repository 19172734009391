/* eslint-disable no-console */
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';

import firebase from '@/services/firebase';

const requestLink = new ApolloLink((operation, forward) => new Observable((observer) => {
  let handle: any;
  Promise.resolve(operation)
    .then(async (requestOperation) => {
      const token = await firebase.auth().currentUser?.getIdToken()
        .catch((err: Error) => {
          console.log('Error getting auth token => ', err);
          return null;
        });

      if (token) {
        requestOperation.setContext({
          headers: {
            authorization: token || '',
          },
        });
      }
    })
    .then(() => {
      handle = forward(operation).subscribe({
        complete: observer.complete.bind(observer),
        error: observer.error.bind(observer),
        next: observer.next.bind(observer),
      });
    })
    .catch(observer.error.bind(observer));

  return () => {
    if (handle) handle.unsubscribe();
  };
}));

const apollo = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
        });
      }
      if (networkError) console.log(`[Network error]: ${JSON.stringify(networkError)}`);
    }),

    requestLink,

    createUploadLink({
      // eslint-disable-next-line no-underscore-dangle
      uri: `${process.env.VUE_APP_API_SERVER_URL || 'http://localhost:4500/graphql'}`,
    }),
  ]),
});

export default apollo;
